"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateTrafficIasScript = void 0;
// @ts-nocheck
const IAS_SCRIPT_URL = "https://cdn.adsafeprotected.com/iasPET.1.js";
const IAS_PUB_ID = "931682";
const IASPET_TIMEOUT = 1000;
const IAS_SCRIPT_ID = "ads-script-ias";
let slotDisplayQueue = [];
function attachIasScript() {
    window.__iasPET = window.__iasPET || {};
    window.__iasPET.queue = window.__iasPET.queue || [];
    window.__iasPET.pubId = IAS_PUB_ID;
    const existingScript = document.getElementById(IAS_SCRIPT_ID);
    if (existingScript) {
        return;
    }
    const script = document.createElement("script");
    script.defer = true;
    script.src = IAS_SCRIPT_URL;
    script.id = IAS_SCRIPT_ID;
    document.head.appendChild(script);
    updateIasConfig();
}
function updateIasConfig() {
    window.__iasAdRefreshConfig = window.__iasAdRefreshConfig || {};
    window.__iasAdRefreshConfig.refreshTargeting = {
        enabled: true,
        targetingKey: 'rfv',
    };
    window.__iasAdRefreshConfig.excludeList = {
        pos: ['skins', 'searchsponsor', 'startrading', 'newssubs']
    };
}
function requestAds() {
    const displaySlot = (slot) => () => {
        slot.fire("slotGoRender");
        window.googletag.display(slot.gpt.id);
    };
    slotDisplayQueue.forEach((slot) => {
        const fn = displaySlot(slot);
        if (slot.displayCalled)
            window.googletag.cmd.push(fn);
        else
            slot.display = () => window.googletag.cmd.push(fn);
    });
}
const __iasPETTimeoutRequestAds = setTimeout(requestAds, IASPET_TIMEOUT);
function iasDataHandler() {
    clearTimeout(__iasPETTimeoutRequestAds);
    window.__iasPET.setTargetingForGPT();
    requestAds();
}
const prepareAndPushPETSlot = (event) => {
    var _a;
    if ((_a = event === null || event === void 0 ? void 0 : event.detail) === null || _a === void 0 ? void 0 : _a.slot) {
        const slot = event.detail.slot;
        slotDisplayQueue.push(slot);
        if (slot.display) {
            slot.display = () => {
                slot.displayCalled = true;
            };
        }
    }
    else {
        return;
    }
    window.googletag.cmd.push(() => {
        const gptSlots = window.googletag.pubads().getSlots();
        const iasPETSlots = gptSlots.map((slot) => {
            const sizes = slot.getSizes().map((size) => {
                if (size.getWidth && size.getHeight)
                    return [size.getWidth(), size.getHeight()];
                else
                    return [1, 1];
            });
            return {
                adSlotId: slot.getSlotElementId(),
                size: sizes,
                adUnitPath: slot.getAdUnitPath(),
            };
        });
        window.__iasPET.queue.push({
            adSlots: iasPETSlots,
            dataHandler: iasDataHandler,
        });
    });
};
const validateTrafficIasScript = () => {
    attachIasScript();
    document.addEventListener("oAds.slotReady", prepareAndPushPETSlot);
};
exports.validateTrafficIasScript = validateTrafficIasScript;
