"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCookie = exports.parseJSONSafe = exports.getLocalStorageObject = exports.updateLocalStorageWithExpiry = exports.getDocumentHeight = exports.getWindowYScroll = exports.getWindowHeight = exports.debounce = exports.waitFor = void 0;
/**
 * Timeout function to wait for specific condition
 * before a passed functon is executed
 */
const waitFor = (isReady) => new Promise((resolve, reject) => {
    setTimeout(() => {
        if (isReady())
            return resolve();
        exports.waitFor(isReady).then(resolve);
    }, 10);
});
exports.waitFor = waitFor;
/**
 * Debounce function with 100ms default delay
 */
function debounce(fn, ms = 100) {
    let timeoutId;
    return function (...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
}
exports.debounce = debounce;
;
/**
 * Get current browser view pane height
 */
function getWindowHeight() {
    return window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight || 0;
}
exports.getWindowHeight = getWindowHeight;
/**
* Get current absolute window scroll position
*/
function getWindowYScroll() {
    return window.pageYOffset ||
        document.body.scrollTop ||
        document.documentElement.scrollTop || 0;
}
exports.getWindowYScroll = getWindowYScroll;
/**
* Get current absolute document height
*/
function getDocumentHeight() {
    return Math.max(document.body.scrollHeight || 0, document.documentElement.scrollHeight || 0, document.body.offsetHeight || 0, document.documentElement.offsetHeight || 0, document.body.clientHeight || 0, document.documentElement.clientHeight || 0);
}
exports.getDocumentHeight = getDocumentHeight;
// localStorage utils
/**
 * Adding JSON item in the localStorage with expiry time
 */
const updateLocalStorageWithExpiry = (key, data, expiryMs) => {
    localStorage.setItem(key, JSON.stringify(Object.assign(Object.assign({}, data), { expiry: new Date().getTime() + expiryMs })));
};
exports.updateLocalStorageWithExpiry = updateLocalStorageWithExpiry;
/**
 * Getting a localStorage stringfied object
 */
const getLocalStorageObject = (key) => {
    const item = localStorage.getItem(key);
    return item ? exports.parseJSONSafe(item) : null;
};
exports.getLocalStorageObject = getLocalStorageObject;
/**
 * Parse a string to JSON without failure
 */
const parseJSONSafe = (str) => {
    try {
        return JSON.parse(str);
    }
    catch (e) {
        return null;
    }
};
exports.parseJSONSafe = parseJSONSafe;
const getCookie = (name) => {
    const rawCookie = document.cookie;
    const match = rawCookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? match[2] : null;
};
exports.getCookie = getCookie;
