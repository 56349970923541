"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDomainUserId = exports.getAppTitleCode = exports.titleCookies = void 0;
const utils_1 = require("../utils");
const titles = {
    brr: 'bankingriskandregulation.com',
    fdi: 'fdiintelligence.com',
    fta: 'ftadviser.com',
    ic: 'investorschronicle.co.uk',
    pwm: 'pwmnet.com',
    sv: 'sustainableviews.com',
    tb: 'thebanker.com',
};
exports.titleCookies = {
    brr: 'sp_ft_id.c447',
    fdi: 'sp_ft_id.ca7e',
    fta: 'sp_ft_id.f776',
    ic: 'sp_ft_id.a53d',
    pwm: 'sp_ft_id.4a89',
    sv: 'sp_ft_id.7f6d',
    tb: 'sp_ft_id.5be3',
};
let code;
function getAppTitleCode() {
    var _a;
    if (!code) {
        code = (_a = Object.entries(titles).find(([_, value]) => window.location.hostname.endsWith(value))) === null || _a === void 0 ? void 0 : _a[0];
    }
    return code;
}
exports.getAppTitleCode = getAppTitleCode;
const getTitleCookieId = () => {
    const titleCode = getAppTitleCode();
    return titleCode ? exports.titleCookies[titleCode] : null;
};
const getDomainUserId = () => {
    const cookieId = getTitleCookieId();
    if (!cookieId) {
        return '';
    }
    const domainUserIdCookie = utils_1.getCookie(cookieId);
    if (!domainUserIdCookie) {
        return '';
    }
    return domainUserIdCookie.split('.')[0];
};
exports.getDomainUserId = getDomainUserId;
