"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onDocumentLoaded = exports.onDocumentReady = void 0;
function onDocumentReady(initLibrary) {
    if (document.readyState !== 'loading') {
        initLibrary();
    }
    else {
        document.addEventListener('DOMContentLoaded', () => {
            initLibrary();
        });
    }
}
exports.onDocumentReady = onDocumentReady;
function onDocumentLoaded(initLibrary) {
    if (document.readyState == 'complete') {
        initLibrary();
    }
    else {
        window.addEventListener('load', () => {
            initLibrary();
        });
    }
}
exports.onDocumentLoaded = onDocumentLoaded;
