"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateAdsConfig = exports.extractCookieAsObj = exports.extractStateFromDOM = void 0;
const utils_1 = require("../utils");
const insertUserCustomOAdsParams_1 = require("./insertUserCustomOAdsParams");
const snowplow_1 = require("./snowplow");
function insertCustomOAdsParams() {
    const oAdsConfig = exports.extractStateFromDOM('data-o-ads-config') || {};
    if (!oAdsConfig.targeting)
        oAdsConfig.targeting = {};
    setFTPageviewId(oAdsConfig);
    setUserData(oAdsConfig);
    setDomainUserId(oAdsConfig);
    setPtTargeting(oAdsConfig);
    setSiteTargeting(oAdsConfig);
    if (isPageArticle()) {
        setArticleData(oAdsConfig);
    }
    exports.updateAdsConfig(oAdsConfig);
}
exports.default = insertCustomOAdsParams;
const extractStateFromDOM = (nodeId) => {
    const node = document.getElementById(nodeId);
    return JSON.parse(decodeURIComponent(node === null || node === void 0 ? void 0 : node.innerHTML));
};
exports.extractStateFromDOM = extractStateFromDOM;
const extractCookieAsObj = (match) => {
    let cookie = undefined;
    try {
        cookie = match ? JSON.parse(window.atob(match)) : undefined;
    }
    catch (e) {
        console.error('Failed to parse cookie: ', match);
    }
    return cookie;
};
exports.extractCookieAsObj = extractCookieAsObj;
const updateAdsConfig = (oAdsConfig) => {
    const oAdsConfigElement = document.getElementById('data-o-ads-config');
    if (oAdsConfigElement) {
        oAdsConfigElement.innerHTML = JSON.stringify(oAdsConfig);
    }
};
exports.updateAdsConfig = updateAdsConfig;
function isPageHome() {
    if (!window.dataLayer)
        return false;
    return window.dataLayer.some(entry => { var _a, _b; return ((_b = (_a = entry === null || entry === void 0 ? void 0 : entry.page) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.asset_type) == 'homepage'; });
}
function isPageTop() {
    if (!window.dataLayer)
        return false;
    return window.dataLayer.some(entry => { var _a, _b; return ((_b = (_a = entry === null || entry === void 0 ? void 0 : entry.page) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.asset_type) == 'top_page'; });
}
function isPageStream() {
    if (!window.dataLayer)
        return false;
    return window.dataLayer.some(entry => { var _a, _b; return ((_b = (_a = entry === null || entry === void 0 ? void 0 : entry.page) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.asset_type) == 'stream_page'; });
}
function isPageArticle() {
    if (!window.dataLayer)
        return false;
    return window.dataLayer.some(entry => !!entry.article);
}
function setFTPageviewId(oAdsConfig) {
    window.ft_pageview_id = window.ft_pageview_id || (Math.round(Math.random() * 10000000000000000));
    oAdsConfig.targeting.ft_pageview_id = window.ft_pageview_id;
}
function setUserData(oAdsConfig) {
    const specialistStateCookie = exports.extractCookieAsObj(utils_1.getCookie('specialist-state'));
    if (specialistStateCookie === null || specialistStateCookie === void 0 ? void 0 : specialistStateCookie.id)
        oAdsConfig.targeting.uuidId = specialistStateCookie === null || specialistStateCookie === void 0 ? void 0 : specialistStateCookie.id;
    if (specialistStateCookie === null || specialistStateCookie === void 0 ? void 0 : specialistStateCookie.type) {
        oAdsConfig.targeting.loggedin = specialistStateCookie.type === 'anonymous' ? 'No' : 'Yes';
        oAdsConfig.targeting.usertype = specialistStateCookie === null || specialistStateCookie === void 0 ? void 0 : specialistStateCookie.type;
    }
    if (insertUserCustomOAdsParams_1.isUserCustomOAdsParamsEnabled()) {
        oAdsConfig.targeting = Object.assign(Object.assign({}, oAdsConfig.targeting), (window._specialist_user_data || {}));
    }
}
function setDomainUserId(oAdsConfig) {
    const domainUserId = snowplow_1.getDomainUserId();
    if (domainUserId)
        oAdsConfig.targeting.domainUserId = snowplow_1.getDomainUserId();
}
function setArticleData(oAdsConfig) {
    var _a, _b, _c;
    const data = window.dataLayer.find(item => !!item.article);
    if (!data || !data.article || !data.article.data)
        return;
    const { article } = data;
    if (!oAdsConfig.targeting.primary_tag && ((_a = article === null || article === void 0 ? void 0 : article.data) === null || _a === void 0 ? void 0 : _a.content_category)) {
        oAdsConfig.targeting.primary_tag = (_b = article === null || article === void 0 ? void 0 : article.data) === null || _b === void 0 ? void 0 : _b.content_category;
    }
    if (!oAdsConfig.targeting.uuid && ((_c = article === null || article === void 0 ? void 0 : article.data) === null || _c === void 0 ? void 0 : _c.content_id)) {
        oAdsConfig.targeting.uuid = article.data.content_id;
    }
}
function setPtTargeting(oAdsConfig) {
    if (oAdsConfig.targeting.pt)
        return;
    if (isPageHome())
        oAdsConfig.targeting.pt = 'fro';
    if (isPageTop())
        oAdsConfig.targeting.pt = 'ind';
    if (isPageStream())
        oAdsConfig.targeting.pt = 'str';
    if (isPageArticle())
        oAdsConfig.targeting.pt = 'art';
}
function setSiteTargeting(oAdsConfig) {
    oAdsConfig.targeting.site = oAdsConfig.gpt.site;
}
